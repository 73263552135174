/* Fonts */
$font: 'SourceSerif';
$font_light: 'SourceSerifLight';
$font_alt: 'Alef';
$font_alt_bold: 'AlefBold';

/* Brand Colors */
$orange: #f69e36;
$almost_black: #322f2f;
$page_bg: #fafafa;
$subject_bg: #f2f2f2;
$service_bg: #8e8e8d;
$sectors_bg: #F9F9F9;
$black: #221e1f;
$grey: #8e8e8d;
$mid_grey: #ADADAD;
$light_grey: #d9d9d9;
$green: #025352;
$dark_green: #113D3C;




/* OLD Colors */
$mid_blue: #0073ae;
$magenta: #e6007e;
$lavander: #774192;
$yellow: #ffd900;
$purple: #a71680;
$plumb: #7f0442;
$white: #ffffff;
$teal: #82CBD1;
$red: #E30613;
$grey_black: #3C3C3B;
$dark_grey: #404040;
$icewhite: #FBFBFB;



