.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 350;
    width: 100%;
    height: 110px;
    font-family: $font;
    font-size: 0.9rem;
    background: $white;
    @media screen and (max-width: 991px) {
        height: 110px;
    }
    .navbar {
        padding: 13px 0;
        .navbar-nav {
            margin-right: 0 !important;
            margin-left: auto;
            ul {
                padding: 0;
                list-style: none;
            }
            li {
                display: inline-block;
                margin: 0 0 0 40px;
                &:last-of-type{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    a {
                        color: $orange;
                        font-size: 13px;
                        vertical-align: middle;
                        padding: 5px 20px;
                        &:hover {
                            color: $black;
                            background-color: $orange;
                        }
                    }
                }
            }
            a {
                text-decoration: none;
                color: $grey;
                font-family: 'Alef', sans-serif;
                text-transform: uppercase;
                &.active {
                    color: $orange;
                    svg g {
                        stroke: $orange;
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            .navbar-nav {
                padding: 25px 0;
                margin-top: 12px;
                background: #fff;
                border: solid 1px $light_grey;
                border-top: none;
                li {
                    margin: 0 40px 0;
                }
            }
        }
    }

    //menus pop on hover
    @media all and (min-width: 992px) {
        .navbar .dropdown-menu-end {
            right:0; left: auto;
        }
        .navbar .nav-item .dropdown-menu {
            display:block; opacity: 0;  visibility: hidden; transition:.3s; margin-top:0;
        }
        .navbar .dropdown-menu.fade-down {
            top:80%; transform: rotateX(-75deg); transform-origin: 0% 0%;
        }
        .navbar .dropdown-menu.fade-up {
            top:180%;
        }
        .navbar .nav-item:hover .dropdown-menu {
            transition: .3s; opacity:1; visibility:visible; top:100%; transform: rotateX(0deg);
        }
    }
}