@import "helpers/mixins";
@import "helpers/placeholders";
@import "helpers/typography";
@import "helpers/variables";
@import "helpers/buttons";

@import "base/reset";

html {
    scroll-behavior: smooth;
}

html, body {
    line-height: 1.4;
    font-size: 1.1rem;
    width: 100%;
    height: 100%;
    font-family: $font;
}

body {
    position: relative;
    // max-width: 1600px;
    margin: 0 auto;
}

@import "layout/common";
@import "layout/footer";
@import "layout/header";
@import "layout/home";
@import "layout/about";
@import "layout/contact";
@import "layout/news";
@import "layout/search";
@import "layout/property";
@import "layout/agents";
@import "layout/sectors-services";