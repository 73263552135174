// New
@font-face {
    font-family: 'SourceSerif';
    src: url('/css/fonts/SourceSerifPro-Regular.ttf');
}

@font-face {
    font-family: 'SourceSerifLight';
    src: url('/css/fonts/SourceSerifPro-Light.ttf');
}

@font-face {
    font-family: 'Alef';
    src: url('/css/fonts/Alef-Regular.ttf');
}

@font-face {
    font-family: 'AlefBold';
    src: url('/css/fonts/Alef-Bold.ttf');
}