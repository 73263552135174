div.grey-bar {
    background-color: $light_grey;
    height: 20px;
    width: 100%;
}

@media screen and (max-width: 600px) {
    .header__brochures {
        width: 100%;
        text-align: center;
    }
    .header__brochures--container.open {
        width: 100%;
        top: 128px;
        padding-top: 25px;
    }
}

main {
    padding-top: 110px;
    p, li {
        a, a:hover, a:visited, a:active {
            text-decoration: none;
            color: $mid_blue;
        }
    }
    p {
        white-space: pre-line;
    }
    h1, h2, h3 {
        font-family: $font;
    }
    h1 {
        font-size: 2rem;
        color: $orange;
        margin-bottom: 10px;
        @media screen and (max-width: 600px) {
            @include font-size(22);
        }
    }
    h2 {
        margin: 25px 0 0;
        font-size: 1.5rem;
        font-weight: 300;
        color: $orange;
    }
    h3 {
        margin-bottom:50px;
        @include font-size(18);
        font-weight: 300;
        color: $black;
        span {
            color: $orange;
            float: right;
        }
    }
    table {
        width: 100%;
        margin-bottom: 10px;
        margin-bottom: 50px;
        .size-m {
            background-color: (rgba($yellow,0.1));
        }
        .size-ft {
            background-color: (rgba($orange,0.1));
        }
        tr {
            border-bottom: 1px solid $orange;
        }
        th, td {
            padding: 5px 8px;
            padding: 10px 15px;
            @include font-size(9);
            color: $black;
            a, a:hover{
                display: block;
                color: $black;
                text-decoration: none;
            }
        }
        th {
            border: 1px solid #fff;
            background: $dark_grey;
            color: #fff;
            font-weight: 400;
        }
        td {
            border-left: 1px solid $light_grey;
            &:first-child {
                border-left: none;
            }
        }
        @media screen and (max-width: 600px) {
            td, th {
                @include font-size(8);
            }
        }
    }
    
    .about {
        background-color: $subject_bg;
        padding: 50px 0;

        h2 {
            color: $almost_black;
            font-size: 40px;
            text-transform: uppercase;
            margin-bottom: 50px;
        }

        p.lead {
            margin-bottom: 50px;
        }

        div.columns {
            display: flex;
            justify-content: space-between;

            div {
                flex-basis: 45%;

                a {
                    color: $orange;
                    font-family: 'Alef', sans-serif;
                    text-transform: uppercase;
                    margin-top: 25px;
                    display: block;
                }
            }
        }
        @media screen and (max-width: 768px) {
            div.columns {   
                div {
                    flex-basis: 48%;
                }
            }
        }
        @media screen and (max-width: 577px) {
            div.columns {   
                flex-direction: column-reverse;
                .image {
                    margin-bottom: 25px;
                }
            }
            
        }
    }

    // JS dynamically added classes
    .sticky-nav-bar-home {
        position: fixed;
        top: 105px;
        transition: 0.5s;
        height: 80px !important;
        .container {
            a {
                transition: 0.5s !important;
                font-size: 0.9em !important;
            }
            @media screen and (max-width: 768px) {
                a {
                    font-size: 0.7em !important;
                }
            }
            @media screen and (max-width: 577px) {
                a {
                    font-size: 0.6em !important;
                }
            }
        }
        @media screen and (max-width: 577px) {
            height: 60px !important;
        }
    }
    .sticky-nav-bar {
        position: fixed;
        top: 110px;
        transition: 0.5s;
        height: 80px !important;
        .container {
            a {
                transition: 0.5s !important;
                font-size: 0.9em !important;
            }
            @media screen and (max-width: 768px) {
                a {
                    font-size: 0.7em !important;
                }
            }
            @media screen and (max-width: 577px) {
                a {
                    font-size: 0.6em !important;
                }
            }
        }
        @media screen and (max-width: 577px) {
            height: 60px !important;
        }
    }
    
    .quick-links-wrap {
        height: 117px;
    }

    .quick-links {
        border-top: solid 15px $light_grey;
        background-color: $almost_black;
        margin-top: 5px;
        z-index: 10;
        height: 117px;
        transition: 0.5s;
        display: flex;
        align-items: center;
        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            a {
                width: 100%;
                transition: 0.5s !important;
                border-right: solid 1px $grey;
                text-align: center;
                text-transform: uppercase;
                color: white;
                text-decoration: none;
                transition: color .2s ease;
                font-size: 1.2em;
                &:hover {
                    color: $orange;

                }
                &:last-of-type {
                    border-right: none;
                    
                }
            }
        }
        @media screen and (max-width: 768px) {
            .container {
                a {
                    font-size: 0.9em;
                }
            }
        }
        @media screen and (max-width: 577px) {
            .container {
                a {
                    font-size: 0.6em;
                }
            }
        }
    }
}

.get-in-touch-widget {
    background-color: $green;
    margin-bottom: 50px;
    .main {
        padding: 35px;
        h4, p {
            color: white;
        }
    
        h4 {
            font-family: $font_alt;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        .btn {
            &.pill {
                background-color: $orange;
                color: white;
                font-weight: 800;
                font-family: $font;
            }
        }
    }
}

.mtt-widget {
    background-color: $orange;
    .image {
        height: 200px;
        background-size: cover;
        background-position:center;
        background-repeat: no-repeat;
    }
    .main {
        padding: 35px;
        h4, p {
            color: white;
        }
    
        h4 {
            font-family: $font_alt;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        a {
            img {
                width: 50px;
            }
        }
    }
}

.row.five-columns .column {
    max-width: 20%;
    flex: 0 0 20%;
}

// Fix Scrollbar with bootstrap modal to avoid layout shifting on open
.modal-open {
    overflow: inherit;
    overflow-y:scroll;
    padding-right: 0px !important;
    .modal {
        padding-right: 0px !important;
    }
}

.news-section {
    padding: 50px 0;
    h2 {
        color: $almost_black;
        font-size: 40px;
        text-transform: uppercase;
        margin-bottom: 50px;
        padding-left: 50px;
        
    }

    article {
        display: flex;
        margin-bottom: 35px;
        // max-height: 250px;
        @media(max-width:768px){
                flex-direction: column;
            }
        img {
            height: auto;
            object-fit: cover;
            margin-right: 25px;
            width: 300px;
            max-height: 250px;
            @media(max-width:768px){
                width:100%;
            }
        }
        div{
            width:70%;
            @media(max-width:768px){
                width:100%;
                margin-top:20px;
            }
        
        }
        span {
            display: block;
            margin-bottom: 10px;
        }

        h5 {
            font-family: $font_alt_bold
        }

        p {
            margin-bottom: 25px;
        }

        a {
            color: $almost_black;
            font-family: $font_alt_bold;
            text-transform: uppercase;
            &:hover {
                text-decoration: underline;
            }
        }
        @media screen and (max-width: 577px) {
            flex-wrap: wrap;
            span {
                margin-top: 10px;
            }
        }
    }

    a.btn.pill {
        margin-top: 20px;
        font-size: 20px;
        padding: 10px 20px;
    }
    @media screen and (max-width: 768px) {
        padding: 0 0 50px;
        article {
            padding: 0 25px;
            img {
                margin: auto;
            }
            span {
                font-size: 1rem;
            }
            h5 {
                font-size: 1rem;
            }
            p {
                font-size: 0.8rem;
                margin-bottom: 5px;
            }
            a {
                font-size: 0.9rem;
            }
        }
    }
    @media screen and (max-width: 577px) {
        .btn.pill {
            margin-left: 25px;
        }
    }
}

.logo-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
    .logo-cell {
        background-color: $light_grey;
        margin: 0 15px 40px 15px;
        height: 255px;
        width: 285px;
        a {
            justify-content: center;
            align-items: center;
            display: flex;
            height: 100%;
            width: 100%;
            img {
                width: 150px;
                max-height: 150px;
            }
        }
    }
}

.search-form__core {
    position: relative;
    font-family: $font;
    padding: 75px 35px 75px 0;
    background-color: $grey;
    padding: 0 25px 25px 0;
    .dropdown button {
        width: 100%;
        height: 30px;
        padding: 3px 28px 3px 8px;
        overflow: hidden;
        line-height: 24px;
        font-size: 0.5rem;
        font-family: $font;
        position: relative;
        border: none;
        background: $grey;
        text-align: left;
        padding: 10px 50px 10px 20px;
        color: $white;
        border: 1px solid $white;   
        & + .dropdown-menu {
            border-left: 1px solid $black;
            border-right: 1px solid $black;
            border-bottom: 1px solid $black;
            margin: 0;
            padding: 0;
            border-radius: 0;
            width: 100%;
            top: 45px;
            max-height: 270px;
            overflow: auto;
            input[type=search] {
                width: 100%;
                padding: 6px 15px;
                border-top: 1px solid #EEE;
                border-bottom: 1px solid #EEE;
            }
            a {
                padding: 6px 15px
            }
            .dropdown-item {
                background-color: #FFF;
                &:hover {
                    color: $white;
                    background-color: $orange;
                }
            }
        }
        &:after {
            position: absolute;
            border: none;
            border-left: solid 1px $grey;
            top:0;
            right:0;
            margin: 0;
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-image: url(/images/dropdown.svg);
        }
    }
    .flex-form {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        & > div {
            margin: 0 7px;
            min-width: 240px;
            .button {
                height: 30px;
                width: 40px;
                padding: 0;
                margin-top: 40px;
                img {
                    height: 80%;
                }
            }
            a {
                margin-left: 15px;
                color: $grey;
            }
        }
        @media screen and (max-width: 519px) {
            justify-content: center;
        }
    }
    @media screen and (max-width: 519px) {
        padding: 0;
        margin-bottom: 25px;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

.gm-style-iw-c {
    border-radius: 0px !important;
    max-width: 200px !important;
    padding: 0 !important;
    .gm-style-iw-d {
        overflow: hidden !important;
        .map-pop-image {
            display: block;
            margin: 0 auto;
            width:100%;
        }
        .map-pop-text {
            padding: 0 15px 15px;
        }
    }
    button {
        background-color: $orange !important;
        opacity: 1 !important;
        margin-top: 10px !important;
        margin-right:10px !important;
        color: $white !important;
    }
}

a.anchor-offset {
    display: block;
    position: relative;
    top: -190px;
    visibility: hidden;
}

.pad-top {
    padding-top: 50px;
}
.pad-bottom {
    padding-bottom: 50px;
}
.pad-vertical {
    padding-bottom: 40px;
    padding-top: 50px;
}


.button, .wpcf7-submit {
    display: inline-block;
    padding: 7px 0;
    text-align: center;
    margin-top: 35px;
    background-color: $white;
    border: 1px solid #F000;
    text-decoration: none !important;
    width: 160px;
    color: $orange;
    font-size: 1rem;
    font-family: $font;
    &:hover {
        text-decoration: none;
        color: $black;
    }
    @media screen and (max-width: 480px) {
        text-align: center;
        width: 100% !important;
    }
    &.center {
        margin: 0 auto;
    }
}

.anchor-link {
    position: relative;
    a {
        position: absolute;
        left: 0;
        top: -110px;
    }
    &.property a {
        top: -172px;
    }
}

.hamburger {
    float:  right;
    padding-right:  20px;
    @media screen and (min-width: 800px) {
        display: none;
    }
    .line{
        width: 25px;
        height: 2px;
        background-color: $black;
        display: block;
        margin: 10px auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        &:nth-child(2) {
            width: 15px;
            margin-right: 0;
        }
    }
    &:hover{
        cursor: pointer;
    }
}

/* ONE */

#hamburger-1.is-active .line:nth-child(2){
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1){
  -webkit-transform: translateY(10px) rotate(45deg);
  -ms-transform: translateY(10px) rotate(45deg);
  -o-transform: translateY(10px) rotate(45deg);
  transform: translateY(10px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3){
  -webkit-transform: translateY(-14px) rotate(-45deg);
  -ms-transform: translateY(-14px) rotate(-45deg);
  -o-transform: translateY(-14px) rotate(-45deg);
  transform: translateY(-14px) rotate(-45deg);
}

.panel .home__our-services {
    @media screen and (max-width: 480px) {
        margin-top: 25px;
        h4 {
            text-align: left;
            margin-bottom: 10px;
        }
    }
}