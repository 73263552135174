.footer {
    width: 100%;
    border-top: 2px solid $light_grey;
    padding: 75px 0 0;
    font-family: $font;
    font-size: 0.85rem;
    background-color: $mid_grey;
    border-bottom: 1px solid $white;
    p {
        color: $white;
        padding-right: 15px;
    }
    .footnote {
        margin-top: 25px;
        margin-bottom: 0;
        a {
            color: $light_grey;
        }
    }
    .social {
        display: flex;
        div {
            flex: 1;
            img {
                max-width: 25px;
                margin-right: 10px;
            }
        }
	}
    .made-by {
        padding-top: 55px;
        font-size: 0.7rem;
        text-align: right;
        a {
            color: $almost_black;
        }
        @media screen and (max-width: 768px) {
            padding-top: 25px;
            font-size: 0.6rem;
        }
        @media screen and (max-width: 577px) {
            padding-top: 15px;
            font-size: 0.4rem;
        }
    }
    @media screen and (max-width: 991px) {
        padding: 15px 0;
        .footnote {
            margin-bottom: 5px;
        }
    }
    .footer__nav {
        ul {
            padding: 0;
            list-style: none;
        }
        li {
            display: inline-block;
            margin: 0 30px 0 0;
        }
        a {
            text-decoration: none;
            text-transform: uppercase;
            color: $white;
            -webkit-transition: color 0.3s;
            transition: color 0.3s;
            &.active {
                color: $orange;
                svg g {
                    stroke: $orange;
                }
            }
            &:hover {
                color: $orange;
            }
            @media screen and (max-width: 480px) {
                &.search__link {
                    display: none;
                }
            }
        }
    }
    @media screen and (max-width: 800px) {
        .wrapper.padded {
            padding: 0;
            .footer__logo {
                margin-left: 20px;
            }
            .footer__nav {
                width: 100%;
                height: 100vh;
                display: none;
                background: #FFF;
                position: relative;
                z-index: 550;
                margin-top: 12px;
                border-top: 1px solid #DDD;
                ul {
                    li {
                        display: block;
                        width: 50%;
                        float: left;
                        font-size: 24px;
                        margin: 0;
                        text-align: center;
                        a {
                            display: block;
                            padding: 8px 0;
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        .wrapper.padded {
            padding: 0 10px;
        }
        .footer__nav {
            width: auto;
            ul li {
                margin-left: 10px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .wrapper.padded {
            .footer__nav {
                margin-top: 15px;
                ul {
                    li {
                        width: 100%;
                        float: none;
                    }
                }
            }
        }
    }
}
