.homepage {
    .quick-links-wrap {
        background-color: $sectors_bg;
    }
    .hero {
        background-color: $orange;
        // height: calc(100vh - 80px);
        overflow: hidden;
        .carousel {
            top: 75px;
            height: 200px;
            padding: 150px 0;
            .carousel-indicators {
                bottom: -255px;
                li {
                    cursor: pointer;
                }
                
            }
            .carousel-item {
                overflow: hidden;
                min-width: 750px;
                transition: 0.1s;
                .text {
                    border-left: 5px solid white;
    
                    h1 {
                        height:252px;
                        font-family: $font_light;
                        padding-left: 50px;
                        color: white;
                        text-transform: uppercase;
                        font-size: 70px;
                        
                    }
                }
    
                .cta {
                    a {
                        margin: 70px 0 0 50px;
                        background-color: white;
                        color: orange;
                        transition: all .2s ease;
    
                        &:hover {
                            background-color: $almost_black;
                            color: $orange;
                        }
                    }
                }
                @media screen and (max-width: 768px) {
                    min-width: 550px;
                    .text {
                        h1 {
                            font-size: 40px;
                            
                        }
                    }
                }
                @media screen and (max-width: 577px) {
                    min-width: 350px;
                    .text {
                        h1 {
                            height:125px;
                            font-size: 30px;
                            
                        }
                    }
                    .cta {
                        a {
                            margin: 10px 0 0 50px;
                        }
                    }
                }
            }
            @media screen and (max-width: 577px) {
                top: 20px;
                .carousel-indicators {
                    bottom: -75px;
                }
            }
        }
        #heroCarousel {
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            z-index: 1;
        }
        .videowrap {
            height: 600px;
            .videobg {
                position: relative;
                height: 600px;
                .videoiframe {
                    position:absolute;
                    top: -37%;
                    left: -37%;
                    width: 175%;
                    height: 175%;
                }
            }
            @media screen and (max-width: 577px) {
                height: 300px;
                .videobg {
                    height: 300px;
                }
            }
        }
    }

    .sector-wrap {
        background-color: $sectors_bg;
    }
    .sectors {
        display: grid;
        background-color: $sectors_bg;
        padding-bottom: 100px;
        grid-template-columns: repeat( 2, minmax(300px, 1fr) );
        grid-auto-rows: auto;
        grid-gap: 30px 30px;
        grid-template-areas:
            "lead ."
            "lead le"
            "sc le"
            "sc le"
            "sc le"
            "sc sp"
            "te hs"
            "te hs"
            "ot hs"
            "ot hs"
            "ot gt"
            "ot gt";

        .lead {
            grid-area: lead;
            padding: 75px;

            h2, p {
                color: $black;
                text-transform: uppercase;
            }

            h2 {
                font-size: 50px;
                margin-bottom: 20px;
            }

            p {
                font-size: 24px;
                font-weight: 200;
                font-family: $font_light;
            }
        }

        .props {
            grid-area: sp;
            display: flex;
            justify-content: center;
            align-items: end;

            a {
                font-size: 24px;
                padding: 10px 30px;
            }
        }

        .text {
            grid-area: te;
            padding: 75px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $font_light;
            p {
                color: $black;
                text-transform: uppercase;
                font-size: 24px;
                font-weight: 200;
            }
        }

        .help {
            grid-area: gt;
            padding: 75px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $font_light;
            p {
                color: $black;
                text-transform: uppercase;
                font-size: 24px;
                font-weight: 200;
            }
        }

        .sector {
            flex-basis: 48%;

            .image {
                height: 320px;
                background-color: $light_grey;
                background-position: center;
                background-repeat: no-repeat;
            }

            .tray {
                background-color: $grey;
                padding: 40px 75px;

                h3, p {
                    color: white;
                }

                h3 {
                    font-family: 'Alef', sans-serif;
                    text-transform: uppercase;
                    margin-bottom: 25px;
                    font-size: 30px;
                }

                p {
                    font-size: 1.1rem;
                    margin-bottom: 20px;
                }

                a {
                    img {
                        width: 50px;
                    }
                }
            }

            &.le {
                grid-area: le;
            }

            &.sc {
                grid-area: sc;
            }

            &.hs {
                grid-area: hs;
            }

            &.ot {
                grid-area: ot;
            }
        }
        @media screen and (max-width: 768px) {
            padding-bottom: 50px;
            display: block;
            .lead {
                padding: 15px;
                h2 {
                    font-size: 45px;
                }
                p {
                    font-size: 22px;
                }
            }
            .props {  
                a {
                    font-size: 24px;
                    padding: 10px 30px;
                    margin-bottom: 25px;
                }
            }
    
            .text {
                padding: 15px;
            }
    
            .help {
                padding: 15px;
            }
            .sector {
                margin: 25px 0 50px;
                .tray {
                    padding: 35px;
                }
            }
        }
    }

    .services {
        // background-image: url(/images/split-bg.svg);
        background: linear-gradient(to top, transparent 60%, $subject_bg 40%);
        background-size: contain;
        padding: 50px 0;

        h2 {
            color: $almost_black;
            font-size: 40px;
            text-transform: uppercase;
            margin-bottom: 50px;
            padding-left: 50px;
        }

        .nav-tabs {
            justify-content: space-around;
            flex-wrap: unset;
            .nav-item {
                flex-basis: 25%;

                a {
                    height: 100%;
                    border-radius: 0;
                    padding: 5px 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-family: 'Alef', sans-serif;
                    text-transform: uppercase;
                    color: white;
                    background-color: #8E8E8D;
                    border: 0;

                    &:nth-child(3) {
                        background-color: #B3B3B3;
                    }

                    &.active {
                        background-color: $orange !important;
                    }
                }

                &:nth-of-type(odd) a {
                    background-color: #B3B3B3;
                }
            }
            @media screen and (max-width: 768px) {
                .nav-item {
                    a {
                        font-size: 2vw;
                    }
                }
            }
        }

        .tab-content {
            background-color: $almost_black;
            padding: 75px;
            min-height: 850px;

            .img {
                border: 20px solid white;
                margin-bottom: 50px;
                min-height: 450px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }

            .wrap {
                display: flex;

                .text {
                    flex-basis: 60%;
                    padding: 0 50px;

                    h4 {
                        color: $orange;
                    }

                    p {
                        color: $light_grey;
                    }
                }

                .links {
                    flex-basis: 40%;
                    padding-top: 30px;
                    padding-left: 50px;

                    a {
                        display: block;
                        margin-bottom: 20px;
                        color: $light_grey;
                        text-decoration: none;
                        transition: color .2s ease;

                        &:hover {
                            color: $orange;
                        }

                        img {
                            max-width: 30px;
                            margin-right: 20px;
                        }
                    }
                }
            }
            @media screen and (max-width: 768px) {
                .wrap {
                    .text {
                        padding: 0;
                    }
                    .links {
                        a {
                            display: flex;
                        }
                        padding-left: 20px;
                    }
                }
            }
            @media screen and (max-width: 577px) {
                margin: 0 -15px;
                padding: 25px;
                .img {
                    min-height: 250px;
                    background-size: cover;
                }
                .wrap {
                    .links {
                        a {
                            img {
                                margin-right: 10px;
                            }
                        }
                        
                        // font-size: 0.8rem;
                    }
                    .text {
                        h4 {
                            font-size: 1.4rem;
                        }
                        p {
                            font-size: 1rem; 
                        }
                    }
                }
            }
        }
    }
}